import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from './context/theme';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './page/Home';
import About from './page/About'
import Contact from './page/Contact'
import Event from './page/Event'
import Program from './page/Program'


function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <header>
        <Header />
      </header>
      <section>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="About" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="Program" element={<Program />} />
            <Route path="Event" element={<Event />} />

          </Routes>
        </BrowserRouter>
      </section>
      <footer>
        <Footer />
      </footer>
    </ThemeProvider>

  );
}

export default App;
