import React from 'react'
import {Box, Grid, Typography, Divider} from "@mui/material"
import image1 from "../image/hand-in-hand.jpg"
import image2 from '../image/chilling-with-the-guys-four-young.jpg'
import image3 from '../image/youth-riding-bicycle.jpg'

const About = () => {
  return (
    <section>
      <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", width:"100%"}}>
        <Box sx={{ padding:"3rem 0 3rem 0"}}>
          <Typography variant='h1' fontSize="3rem" fontWeight="bold" textAlign="center" color="secondary">關於我們</Typography>
        </Box>
        <Box sx={{ display:"flex", width:"100%", justifyContent:"center", alignItems:"center"}}>
          <img src={image1} alt='hand in hand' width="90%"/>
        </Box>
          <Divider sx={{margin:"2rem 5% 2rem 5%"}} />
        
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant='body1' color="secondary" fontSize="1.2rem" sx={{margin:"4%"}}> 
              香港基層青年關注協會是一所非牟利慈善機構，致力於關注基層青年的福利和發展，並提供相關的支援和服務。促進他們的參與和發聲，並為他們提供支援和服務，以實現基層青年的自我價值和社會貢獻。
            </Typography>
            <Typography variant='h2' textAlign="center" color="secondary" fontSize="2rem" fontWeight="bold" sx={{margin:"4%"}}> 
              服務範疇
            </Typography>
            <Typography variant='body1' color="secondary" fontSize="1.2rem" sx={{margin:"4%"}}> 
              香港基層青年關注協會為基層青年提供各種支援和服務，包括教育、文化、體育、社區服務及職業培訓等方面，以促進其發展和成長。協會亦關注基層青年的生存和發展狀況，提供各種援助和支持，幫助他們克服困難，改善生活質素。此外，協會鼓勵基層青年積極參與社會服務，提供不同的平台讓他們以身體力行的方式回饋社會。
            </Typography>
          </Grid>
         
          <Grid item xs={12} md={6}>
            <Box sx={{ display:"flex", width:"100%", justifyContent:"center", alignItems:"center"}}>
              <img src={image2} alt='Boys talk' width="90%"/>
            </Box>
          </Grid>
          
        </Grid>
        <Grid container sx={{marginTop:"2%"}}>
        <Grid item xs={12} md={6}>
          <Typography variant='h2' textAlign="center" color="secondary" fontSize="2rem" fontWeight="bold" sx={{margin:"4%"}}> 
              經費來源
            </Typography>
            <Typography variant='body1' color="secondary" fontSize="1.2rem" sx={{margin:"4%"}}> 
              香港基層青年關注協會是一所非牟利慈善機構，其經費來源主要來自政府資助、捐款、基金會撥款、企業贊助、籌款活動等。協會致力於為基層青年提供支援和服務，所有收入均用於推動協會的宗旨和使命，以改善基層青年的生存和發展狀況，提高他們的生活質素和未來發展潛力。
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display:"flex", width:"100%", justifyContent:"center", alignItems:"center"}}>
              <img src={image3} alt='Boys ride bicycle' width="90%"/>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{margin:"2rem 5% 2rem 5%"}} />
      </Box>
    </section>
  )
}

export default About