import React from 'react'
import { Grid, Box, Typography, Divider, CardHeader, Card, CardMedia, CardContent } from '@mui/material'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel';
import image01 from '../image/portrait-of-happy-asian-family.jpg'
import image02 from '../image/asian-woman-outdoors.jpg'
import image03 from '../image/volunteer.jpg'
import image04 from '../image/group-of-business-consultant.jpg'
import imgSilder01 from '../image/asian-college-student.jpg'
import imgSilder02 from '../image/volunteers-supporting-each-other.jpg'
import imgSilder03 from '../image/smiling-young-woman-volunteering.jpg'


const Home = () => {

  return (

    <section>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "2%" }}>
        <Box>
          <Carousel showArrows={false} infiniteLoop={true} showThumbs={false} stopOnHover={false} showStatus={false} autoPlay={true}>
            <div>
              <Box padding={5} position="relative">
                <img src={imgSilder01} alt="training" style={{ opacity: "60%" }} />
                <Typography position="absolute" top="25rem" right="10rem" variant='h3' fontWeight="bold" boxShadow="1px 1px 10px grey" bgcolor="grey" sx={{ color: "white" }}>培訓基層人士發展</Typography>

              </Box>
            </div>
            <div>
              <Box padding={5} position="relative">
                <img src={imgSilder02} alt="volunteer" style={{ opacity: "60%" }} />
                <Typography position="absolute" top="15rem" left="10rem" variant='h3' fontWeight="bold" boxShadow="1px 1px 10px grey" bgcolor="grey" sx={{ color: "white" }}>集合社會各界力量</Typography>
              </Box>
            </div>
            <div>
              <Box padding={5} position="relative">
                <img src={imgSilder03} alt="care" style={{ opacity: "60%" }} />
                <Typography position="absolute" top="25rem" right="20rem" variant='h3' fontWeight="bold" boxShadow="1px 1px 10px grey" bgcolor="grey" sx={{ color: "white" }}>關懷基層各種需要</Typography>
              </Box>
            </div>
          </Carousel>
        </Box>
        <Divider sx={{ margin: "2rem" }} />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: "1.5%" }}>
              <Typography variant='h1' color="secondary" fontSize="1.7rem" fontWeight="bold" textAlign="center" marginBottom={2}>香港基層青年關注協會</Typography>
              <Typography variant='body1' color="secondary" fontSize="1rem" marginBottom={2}>
                香港基層青年關注協會是一所非牟利慈善機構，致力於關注基層青年的福利和發展，並提供相關的支援和服務。其服務宗旨主要包括三個方面：
              </Typography>
              <Typography variant='body1' color="secondary" fontSize="1rem" marginBottom={2}>
                <b>關注基層青年的福利: </b>協會關注基層青年的生存和發展狀況，特別是那些來自弱勢群體的年輕人，例如低收入家庭、單親家庭、新移民家庭等。協會致力於提供各種支援和服務，幫助這些年輕人克服困難，改善生活質素，並擁有更美好的未來。
              </Typography>
              <Typography variant='body1' color="secondary" fontSize="1rem" marginBottom={2}>
                <b>促進基層青年的發展: </b>協會鼓勵和支持基層青年參與教育、文化、體育和社區服務等活動，並提供相關的培訓和資源，幫助他們發揮潛能，提高能力和自信心，成為社會有用的人才。
              </Typography>
              <Typography variant='body1' color="secondary" fontSize="1rem" marginBottom={2}>
                <b>推動基層青年的參與和發聲: </b>協會倡導基層青年積極參與社會事務，關注社會公義和公平，並為基層青年發聲。協會為他們提供發聲平台，讓他們表達意見和訴求，以推動社會進步和改革。
              </Typography>
              <Typography variant='body1' color="secondary" fontSize="1rem" marginBottom={2}>
                總的來說，香港基層青年關注協會的服務宗旨是關注基層青年的福利和發展，促進他們的參與和發聲，並為他們提供支援和服務，以實現基層青年的自我價值和社會貢獻。
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <img src={image01} alt='Family' width="100%" style={{ boxShadow: "1px 1px 4px grey" }} />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "2rem" }} />
        <Box sx={{ display: "flex", flexDirection: "column", margin: "3rem 2% 3rem 2%" }}>
          <Typography variant='h2' color="secondary" fontSize="1.5rem" fontWeight="bold" textAlign="center" marginBottom={2} >
            我們的服務
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Card sx={{ margin: "5px" }}>
              <CardHeader title="技能訓練" sx={{ textAlign: "center" }} />
              <CardMedia
                component="img"
                width="100%"
                image={image02}
                alt='Youth Lady'
              />
              <CardContent>
                <Typography paragraph>
                  本會提供各種技能訓練課程，包括電腦操作、職業培訓、語言學習等，旨在幫助基層青年發展職業技能，提高就業競爭力，實現個人理想和人生目標。
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ margin: "5px" }}>
              <CardHeader title="義工服務" sx={{ textAlign: "center" }} />
              <CardMedia
                component="img"
                width="100%"
                image={image03}
                alt='volunteer'
              />
              <CardContent>
                <Typography paragraph>
                  本會鼓勵青年積極參與義工服務，透過各種計劃和活動，為基層社群提供支援和幫助，並協助義工發掘個人潛能，增加社交經驗，培養公民意識和社會責任感。
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ margin: "5px" }}>
              <CardHeader title="諮詢及輔導服務" sx={{ textAlign: "center" }} />
              <CardMedia
                component="img"
                width="100%"
                image={image04}
                alt='Consultant'
              />
              <CardContent>
                <Typography paragraph>
                  本會提供專業的諮詢及輔導服務，包括情緒健康、家庭及人際關係、就業等範疇，協助基層青年解決問題，提升生活質素，實現個人發展。
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </section>
  )
}

export default Home