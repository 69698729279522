import React from 'react'
import { Box, Grid, Typography, Button, Divider, TextField } from "@mui/material"
import logo from "../image/hkgycg-name-logo.png"

const Footer = () => {
  return (
    <footer>
    <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "#c7c7c7", padding: "2rem 5rem 2rem 5rem" }}>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography variant='h5' fontWeight="bold" color="secondary" textAlign="center">獲取最新資訊</Typography>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center"}}>
          <TextField variant="standard" color="secondary" focused sx={{ width: "350px", display: "flex", input:{ color: 'secondary' }}} label="輸入電郵" />
          
        </Box>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center"}}>
          <Button color='secondary'>送出</Button>
        </Box>
      </Box>
      <Divider sx={{margin:"2rem 0 2rem 0"}}/>
      <Grid container sx={{marginTop:"2rem"}}>
        <Grid item xs={12} md={6}>
          <Box sx={{display:"flex", flexDirection:"column", width:"100%", justifyContent:"center", alignItems:"center"}}>
            <img src={logo} alt='Logo' width="50%"/>
          </Box>
          <Typography container="body1" color="secondary" marginTop={2}>
            香港基層青年關注協會為基層青年提供各種支援和服務，包括教育、文化、體育、社區服務及職業培訓等方面，以促進其發展和成長。協會亦關注基層青年的生存和發展狀況，提供各種援助和支持，幫助他們克服困難，改善生活質素。此外，協會鼓勵基層青年積極參與社會服務，提供不同的平台讓他們以身體力行的方式回饋社會。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{display:"flex", flexDirection:"column", width:"100%", justifyContent:"center", alignItems:"center"}}>
            <Typography container="h5" textAlign="center" color="secondary" fontWeight="bold" fontSize="2rem" margin="1rem 0 1rem 0">聯絡我們</Typography>
            
            <Typography textAlign="left" color="secondary" container="body1">地址：土瓜灣馬頭圍道161號2樓</Typography>
            <Typography textAlign="left" color="secondary" container="body1">電郵：info@hkgycg.org</Typography>
          </Box>
        </Grid>
      </Grid>
      
    </Box>
    <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "#616161", padding: "2rem 1rem 2rem 1rem" }}>
        <Typography textAlign="center" color="white">© Copyright 2023 by Hong Kong Grassroots Youth Concern Group Limited</Typography>
      </Box>
    </footer>
  )
}

export default Footer