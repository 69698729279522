import React from 'react'
import { Box, Grid, Typography, Button, Divider } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import image01 from '../image/young-asian-teenage-girl-playing-skateboard.jpg'
import image02 from '../image/home-repair.jpg'
import image03 from '../image/young-woman-interviewing.jpg'
import image04 from '../image/volunteer-packing-donated-food.jpg'


const Program = () => {
  return (
    <section>
      <Box sx={{display:"flex", flexDirection:"column", margin:"2%"}}>
        <Typography variant='h1' fontSize="3rem" fontWeight="bold" textAlign="center" color="secondary">最新活動</Typography>
        <Box sx={{margin:"2% 0 2% 0", display:"flex", width:"100%", justifyContent:"center"}}>
          <img src={image01} alt='girl playing skateboard' width="80%" style={{boxShadow:"1px 1px 5px grey"}} />
        </Box>
        <Divider sx={{ margin: "2rem" }} />
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography variant='h3' color="secondary" sx={{fontSize:"1.5rem", fontWeight:"bold", textAlign:"center"}}>影視製作就業講座</Typography>
            <Typography variant='body1' color="secondary" sx={{fontSize:"1rem", fontWeight:"bold", textAlign:"left", marginTop:"15px"}}>影視製作就業講座是為有志投身影視行業的人士舉辦的講座，講解行業趨勢、職位介紹、技能要求、求職技巧等，幫助學員掌握求職竅門，了解產業現況，提高就業競爭力，並開拓職業發展之路。</Typography>
            <Typography variant='body1' color="secondary" sx={{fontSize:"1rem", fontWeight:"bold", textAlign:"left", marginTop:"15px"}}>日期：2023年9月11日</Typography>
            {/* <Button variant='contained' sx={{width:"100%", marginTop:"15px", '&:hover': {backgroundColor: 'primary.light'}}}><WhatsAppIcon/><Typography>立即查詢</Typography></Button> */}
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{display:"flex", width:"100%", justifyContent:"center"}}>
              <img src={image03} alt='young woman interviewing' style={{width:"90%", margin:"1rem"}}/>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "2rem" }} />
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography variant='h3' color="secondary" sx={{fontSize:"1.5rem", fontWeight:"bold", textAlign:"center"}}>同心送禮星期日</Typography>
            <Typography variant='body1' color="secondary" sx={{fontSize:"1rem", fontWeight:"bold", textAlign:"left", marginTop:"15px"}}>同心送禮星期日是香港基層青年關注協會舉辦的義工活動之一，旨在關懷社區長者，為他們送上溫馨和關愛，透過贈送禮物和陪伴，增加他們的生活樂趣，提升社區凝聚力，也為義工提供實踐愛心的機會。</Typography>
            <Typography variant='body1' color="secondary" sx={{fontSize:"1rem", fontWeight:"bold", textAlign:"left", marginTop:"15px"}}>日期：每月最後一個星期日</Typography>
            {/* <Button variant='contained' sx={{width:"100%", marginTop:"15px", '&:hover': {backgroundColor: 'primary.light'}}}><WhatsAppIcon/><Typography>立即查詢</Typography></Button> */}
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{display:"flex", width:"100%", justifyContent:"center"}}>
              <img src={image04} alt='volunteer packing donated food' style={{width:"90%", margin:"1rem"}}/>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "2rem" }} />
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography variant='h3' color="secondary" sx={{fontSize:"1.5rem", fontWeight:"bold", textAlign:"center"}}>家居維修基礎證書課程</Typography>
            <Typography variant='body1' color="secondary" sx={{fontSize:"1rem", fontWeight:"bold", textAlign:"left", marginTop:"15px"}}>家居維修基礎證書課程是一門培訓課程，旨在教授學員家居維修技能，包括電器、水電、木工等方面，幫助學員掌握基本技能，提高解決問題的能力，並為他們日常生活和職業發展提供實用技能。</Typography>
            <Typography variant='body1' color="secondary" sx={{fontSize:"1rem", fontWeight:"bold", textAlign:"left", marginTop:"15px"}}>日期：2023年8月</Typography>
            <Typography variant='body1' color="secondary" sx={{fontSize:"1rem", fontWeight:"bold", textAlign:"left", marginTop:"15px"}}>完成課程可獲得工作實習機會</Typography>
            {/* <Button variant='contained' sx={{width:"100%", marginTop:"15px", '&:hover': {backgroundColor: 'primary.light'}}}><WhatsAppIcon/><Typography>立即查詢</Typography></Button> */}
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{display:"flex", width:"100%", justifyContent:"center"}}>
              <img src={image02} alt='home repair' style={{width:"90%", margin:"1rem"}}/>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "2rem" }} />
      </Box>
    </section>
  )
}

export default Program