import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
    palette: {
      primary: {
        main: "#FFB357",
        contrastText:"#333389"
      },
      secondary:{
        main: "#333389",
        contrastText:"#FFB357"
      },
      text:{
        primary:"#333389"
      }
    },
  })

  export {mainTheme}