import React, { useState } from 'react'
import { AppBar, Toolbar, Grid, Box, Typography, Tabs, Tab, Button, IconButton, useMediaQuery, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import logo from "../image/hkgycg-name-logo.png"
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import EmailIcon from '@mui/icons-material/Email'
import MenuIcon from '@mui/icons-material/Menu'

const Header = () => {
    const wh = useTheme()
    const matches = useMediaQuery(wh.breakpoints.up('md'))
    const [drawerOpen, setDrawerOpen] = useState(false)
    const link = [
        ['主頁','/home'],
        ['關於本會','/about'],
        ['最新活動','/program'] ,
        // ['特別節目','/event'] ,
        ['聯絡我們','/contact']  ]

    return (
        <AppBar sx={{position:"static"}}>
            <Toolbar>
                <Box sx={{ margin: "2%" }}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ display: "flex", justifyContent: "center", padding: "3px" }}>
                                <img src={logo} width="50%" alt='logo' />
                            </Box>
                        </Grid>
                        {matches &&
                            <Grid item xs={12} md={6} >
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right", paddingRight: "3%" }}>
                                        <PermPhoneMsgIcon color="secondary" sx={{ paddingRight: "0.5rem" }} />
                                        <Typography variant='body1' color="secondary">3525 0073</Typography>
                                        <EmailIcon color="secondary" sx={{ paddingRight: "0.5rem", paddingLeft: "1rem" }} />
                                        <Typography variant='body1' color="secondary">info@hkgycg.org</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                        <Tabs value={false} textColor="text.secondary">
                                            <Tab label="主頁" href="/" />
                                            <Tab label="關於本會" href="/about" />
                                            <Tab label="最新活動" href="/program" />
                                            {/* <Tab label="特別節目" href='event' /> */}
                                            <Tab label="聯絡我們" href='contact' />
                                        </Tabs>
                                    </Box>
                                </Box>

                            </Grid>
                        }
                        {matches &&
                            <Grid item xs={12} md={3}>

                                <Box display="flex" justifyContent="center" padding="5%">
                                    <Button variant="contained" color='secondary' href="/program">
                                        最新活動
                                    </Button>
                                </Box>
                            </Grid>
                        }
                        {!matches &&
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <IconButton onClick={() => setDrawerOpen(true)}><MenuIcon color='secondary' /></IconButton>
                                </Box>
                                <Drawer anchor='top' open={drawerOpen} onClose={() => { setDrawerOpen(false) }}>
                                    <List>
                                        {link.map((text, index) => (
                                            <ListItem key={text[0]} disablePadding>
                                                <ListItemButton component="a" href={text[1]}>
                                                    <ListItemText primary={text[0]} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Drawer>
                            </>
                        }
                    </Grid>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header