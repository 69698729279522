import React from 'react'
import { Box, Grid, Typography, Button, Divider, FormControl, TextField } from '@mui/material'
import image01 from '../image/asian-family.jpg'
import BusinessIcon from '@mui/icons-material/Business'
import CallEndIcon from '@mui/icons-material/CallEnd'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const Contact = () => {
  return (
    <section>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }}>
        <Box sx={{ padding: "3rem 0 3rem 0" }}>
          <Typography variant='h1' fontSize="3rem" fontWeight="bold" textAlign="center" color="secondary">聯絡我們</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <iframe title='address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.981633920764!2d114.18524591163307!3d22.31653434222056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400d9cf99c67b%3A0xddaebca974a1f3a!2sGainfull%20Centre%2C%20161%20Ma%20Tau%20Wai%20Rd%2C%20To%20Kwa%20Wan%2C%20Hong%20Kong!5e0!3m2!1sen!2suk!4v1717676896623!5m2!1sen!2suk" width="90%" height="300" style={{ border: 0, boxShadow: "1px 1px 5px grey" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Box>
        <Divider sx={{ margin: "2rem" }} />
        <Grid container sx={{ margin: "3rem 0 3rem 0" }}>
          <Grid item xs={12} md={4}>
            <img src={image01} alt="young servcing" width="90%" style={{ boxShadow: "1px 1px 5px grey", marginBottom: "10%", marginLeft:"5%"}} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "10%", marginLeft:"5%" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", paddingRight: "3%", marginBottom: "5px" }}>
                <BusinessIcon sx={{ marginRight: "5px" }} color='secondary' /><Typography variant='body1' fontWeight="bold" color="secondary">土瓜灣馬頭圍道161號2樓</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", paddingRight: "3%", marginBottom: "5px" }}>
                <CallEndIcon sx={{ marginRight: "5px" }} color='secondary' /><Typography variant='body1' fontWeight="bold" color="secondary">3525 0073</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", paddingRight: "3%", marginBottom: "5px" }}>
                <MarkEmailReadIcon sx={{ marginRight: "5px" }} color='secondary' /><Typography variant='body1' fontWeight="bold" color="secondary">info@hkgycg.org</Typography>
              </Box>
            </Box>
            
          </Grid>
          
          <Grid item xs={12} md={4}>
            
            <Box component="form" noValidate autoComplete="off" sx={{display:"flex", width:"100%", justifyContent:"center"}}>
              
              <FormControl sx={{ width: '20rem' }}>
                <Typography variant='h5' color="secondary" fontWeight="bold">查詢</Typography>
                <TextField label="聯絡人" color="secondary" focused margin="dense" />
                <TextField label="電郵" color="secondary" focused margin="dense" />
                <TextField
                  color="secondary"
                  label="查詢內容"
                  margin="dense"
                  multiline
                  focused
                />
                <Button variant='contained' color="secondary" sx={{ color: 'white' }}>送出</Button>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ margin: "2rem" }} />
    </section>
  )
}

export default Contact